import React, {FunctionComponent} from "react";
import {BackButton} from "../../components/BackButton/BackButton";
import {Text} from "../../components/Text/Text";
import {TEXT_MOCK} from "./SoftwareGuide.constants";
import {Button} from "../../components/Button/Button";

export const SoftwareGuide: FunctionComponent = () => {

	function handleClickDownload(fileName: string) {
		let file_path = fileName;
		let a = document.createElement('a');
		a.href = file_path;
		a.download = file_path.substring(file_path.lastIndexOf('/') + 1);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return (
		<div className={'software-guide-page'}>
			<BackButton />
			<Text font={'black'} size={'SIZE22'} color={'pink'}>{TEXT_MOCK[0]}</Text>
			<div className={'software-guide-page--video'}>
				<iframe width="100%" height="315" src="https://www.youtube.com/embed/6Ashp58B6uw?si=O9K1-QzzHO6cowF_"
						title="YouTube video player" frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
				>
				</iframe>
			</div>
			<div className={'software-guide-page--title-text'}>
				<Text font={'book'} size={'SIZE18'} color={'white'}>{TEXT_MOCK[1]}</Text>
			</div>
			<Text font={'book'} size={'SIZE22'} color={'pink'}>{TEXT_MOCK[2]}</Text>
			<div className={'software-guide-page--title-download'}>
				<Button onClick={() => handleClickDownload('/assets/files/Kavaca_SmartCut_Manual.pdf')} color={'pink'} >{'DOWNLOAD'}</Button>
			</div>
			<Text font={'book'} size={'SIZE18'} transform={'uppercase'} color={'grey'}>{TEXT_MOCK[5]}</Text>
			<div className={'software-guide-page--title-download'}>
				<Button onClick={() => handleClickDownload('/assets/files/Compatible_plotter_list.pdf')} color={'grey'} >{'DOWNLOAD'}</Button>
			</div>
			<div className={'software-guide-page--white-space'}>
				<Text font={'book'} size={'SIZE18'} color={'white'}>{TEXT_MOCK[3]}</Text>
			</div>
			<Text font={'book'} size={'SIZE18'} color={'white'}>
				{'support@nanoshine-group.com'}
			</Text>
		</div>
	)
}
