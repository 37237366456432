import {FunctionComponent, useEffect, useState} from "react";
import {API_URL, HEADERS} from "../../utils/constants";

export const TestPage : FunctionComponent = () => {
	const [index, setIndex] = useState(0);
	const [isPopUpShow, setPopUpShow] = useState(false);
	const [userData, setUserData] = useState<any | null>(null);

	const orders = [
		{
			name: "50m2",
			description: "+ 5 free auto-layouts",
			price: "159.00",
			trial: 1
		},
		{
			name: "100m2",
			description: "+ 10 free auto-layouts",
			price: "299.00",
			trial: 1
		},
		{
			name: "10",
			description: "auto-layouts",
			price: "45.00",
			trial: 1
		},
		{
			name: "100",
			description: "auto-layouts",
			price: "399.00",
			trial: 1
		},
		{
			name: "Monthly unlimited",
			description: "without auto-layouts",
			price: "149.00",
			trial: 1
		},
		{
			name: "Annual unlimited",
			description: "without auto-layouts",
			price: "1490.00",
			trial: 1
		},
	]

	useEffect(() => {
		const FetchData = async () => {
			return await fetch(API_URL + '/api/products', { headers: HEADERS });
		}

		const FetchUserData = async () => {
			return await fetch(API_URL + '/api/users/current', { headers: HEADERS });
		}

		FetchUserData().then(response => response.json()).then(res => {
			setUserData(res);
		});

		FetchData().then((res) => console.log(res));
	}, []);


	function handleCreateProduct() {
		fetch(API_URL + '/api/products', {
			headers: HEADERS,
			method: 'POST',
			body: JSON.stringify(orders[index])
		});
		setIndex(prevState => prevState + 1)
	}

	function handleShowPopUp() {
		setPopUpShow(true);
	}

	return (
		<div style={{ color: 'white' }}>
			{isPopUpShow && 'Pop up is shown'}
			<div><button disabled onClick={handleCreateProduct}>Create Product</button></div>
			<div><button onClick={handleShowPopUp}>Show PopUp</button></div>
			<div>
				{userData ? (
					JSON.stringify(userData)
				): null }
			</div>
		</div>
	)
}
