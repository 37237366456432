export const TEXT_MOCK = [
	'JOIN US',
	'THE GLOBAL LEADER IN NANOCERAMIC COATINGS AND FILMS',
	'Nanoshine Group Corp. is the global leader in nanoceramic surface protection. We offer a range of ceramic coating and paint protection film products each formulated for specific surfaces.',
	'Our ceramic coatings and PPF are designed for automotive, marine, aviation and industrial applications.',
	'With Ceramic Pro and Kavaca, you can start a successful business from the ground up using a proven, high-profit business model. The products and services at your disposal are in high demand worldwide.',
	'With the right location, the right equipment, and the right staff, your business will see immediate results.',
	'JOIN OUR NETWORK'
];

export const PUZZLE_BLOCK = [
	{
		title: '5000+',
		message: 'installers worldwide'
	},
	{
		title: '80+',
		message: 'countires served'
	},
	{
		title: '100.000+',
		message: 'vehicles and\nvessels coated\nmonthly'
	},
	{
		title: '$100+',
		message: 'billions in assets protected'
	}
];
