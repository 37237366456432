import {SideMenuItems} from "./components/SideMenu/SideMenu.interfaces";
import {IRoutesType, pageNames} from "./utils";
import {DatabasePage} from "./features/DatabasePage/DatabasePage";
import {HomePage} from "./features/HomePage/HomePage";
import {BookmarksPage} from "./features/BookmarksPage/BookmarksPage";
import {ArchivePage} from "./features/ArchivePage/ArchivePage";
import {KavacaIONPage} from "./features/KavacaIONPage/KavacaIONPage";
import {TestPage} from "./features/TestPage/TestPage";
import {JoinPage} from "./features/JoinPage/JoinPage";
import {ProductPage} from "./features/ProductPage/ProductPage";
import {AddProductPage} from "./features/AddProductPage/AddProductPage";
import {SoftwareGuide} from "./features/SoftwareGuide/SoftwareGuide";

export const routeList: IRoutesType[] = [
	{
		path: '/',
		isMenu: true,
		title: pageNames.HOME,
		element: <HomePage />
	},
	{
		path: '/data-base',
		isMenu: true,
		title: pageNames.DATABASE,
		element: <DatabasePage />
	},
	{
		path: '/book-marks',
		isMenu: true,
		title: pageNames.BOOKMARKS,
		element: <BookmarksPage />
	},
	{
		path: '/archive',
		isMenu: true,
		title: pageNames.ARCHIVE,
		element: <ArchivePage />
	},
	{
		path: '/kavaca-ion',
		isMenu: false,
		title: pageNames.KAVACAION,
		element: <KavacaIONPage />
	},
	{
		path: '/join-us',
		isMenu: false,
		title: pageNames.JOIN,
		element: <JoinPage />
	},
	{
		path: '/buy-credits',
		isMenu: false,
		title: pageNames.PRODUCTS,
		element: <ProductPage />
	},
	{
		path: '/software-guide',
		isMenu: true,
		title: pageNames.SOFTWAREGUIDE,
		element: <SoftwareGuide />
	},
	{
		path: '/test-page',
		isMenu: true,
		title: pageNames.TEST,
		element: <TestPage />
	},
	{
		path: '/add-product',
		isMenu: true,
		title: pageNames.ADDPRODUCTS,
		element: <AddProductPage />
	},
];

export const sideMenuRoutes = routeList.reduce((acc: SideMenuItems[], route) => {
	if (route.isMenu) {
		acc.push({
			path: route.path || '/',
			title: route.title,
		})
	}
	return acc;
}, []);
