import {FunctionComponent, useState, KeyboardEvent, useContext, useRef, useEffect} from "react";
import { ReactComponent as Close } from "../../assets/icons/close_black.svg";
import { ReactComponent as Enter } from "../../assets/icons/enter.svg";
import { ReactComponent as AIBotButton } from "../../assets/icons/ai_icon.svg";
import {Flex} from "../Flex/Flex";
import {Text} from "../Text/Text";
import {HELLO_MESSAGE} from "./ChatBot.constants";
import classNames from "classnames";
import {API_URL, HEADERS} from "../../utils/constants";
import {AuthContext} from "../../App";

export const ChatBot : FunctionComponent = () => {
	const bodyRef = useRef<HTMLDivElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [{ authToken }] = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [messageHistory, setMessageHistory] = useState<Array<{ type: string, message: string }>>([
		{
			type: 'bot',
			message: HELLO_MESSAGE
		}
	]);

	const SendQuestion = async () => {
		return await fetch(
			API_URL + `/api/ai/assist?question=${message}`,
			{
				headers: {
					...HEADERS,
					'Authorization': `Bearer ${authToken}`
				},
				method: 'GET',
			},
		);
	}

	useEffect(() => {
		if (bodyRef.current) {
			bodyRef.current?.scrollIntoView();
		}
	}, [isLoading]);

	async function onEnterPress(key: string, event?: KeyboardEvent<HTMLTextAreaElement>) {
		if (key === 'Enter' && message !== '') {
			if (event) event.preventDefault();
			const newHistory = [ ...messageHistory ];
			newHistory.push({
				type: 'human',
				message,
			})
			setMessageHistory(newHistory);
			setMessage('');
			setIsLoading(true);
			SendQuestion().then(response => response.json()).then(res => {
				if (res.data) {
					newHistory.push({
						type: 'bot',
						message: res.data,
					})
					setMessageHistory(newHistory);
				}
				setIsLoading(false);
			});
		}
	}

	return (
		<>
			<button onClick={() => setIsOpen(!isOpen)} className={classNames('chat-bot--trigger', {
				['chat-bot--trigger--hidden']: isOpen
			})}>
				<AIBotButton />
			</button>
			{isOpen ? (
				<div className={'chat-bot--wrapper'}>
					<Flex className={'chat-bot--header'} justifyContent={'center'} alignItems={'center'}>
						<Text size={'SIZE24'} color={'pink'} font={'book'}>
							{'AI Assistant powered by ChatGPT'}
						</Text>
						<button onClick={() => setIsOpen(!isOpen)} className={'chat-bot--header--close'}>
							<Close/>
						</button>
					</Flex>
					<Flex className={'chat-bot--body'} justifyContent={'flexStart'} flexDirection={'column'}>
						{messageHistory.map((messageItem, index) => {
							return (
								<div key={`${messageItem.type}-${index}`} className={classNames('chat-bot--body--message', {
									['chat-bot--body--message--bot']: messageItem.type === 'bot',
									['chat-bot--body--message--human']: messageItem.type === 'human',
								})}>
									<Text font={'helvetica'} size={'SIZE18'} color={'black'}>
										{messageItem.message}
									</Text>
								</div>
							)
						})}
						{isLoading ? (
							<div className={'chat-bot--loading--wrapper'}>
								<div className={'chat-bot--loading'}>{'Typing...'}</div>
							</div>
						) : null}
						<div ref={bodyRef} />
					</Flex>
					<div className={'chat-bot--textarea'}>
					<textarea
						placeholder={'Enter your question...'}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						onKeyDown={(e) => onEnterPress(e.key, e)}
						className={'chat-bot--textarea--input'}
					/>
						<button onClick={() => onEnterPress('Enter')} className={'chat-bot--textarea--button'}>
							<Enter />
						</button>
					</div>
				</div>
			) : null}
		</>
	)
};
