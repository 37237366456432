export const TEXT_MOCK = [
	'KAVACA ION',
	'REVOLUTION IN THE PPF MARKET',
	'Highly advanced protection film of a next generation. Unique composition make it stand out as a new separate class of surface protection.',
	'Product designed to combine the best qualities of paint protection films and nanoceramic coatings, excluding their weaknesses and boosting the advantages to a completely new level.',
	'Manufactured on a modern production line of the latest generation. The combination of the best equipment and technologies, as well as highly qualified personnel and advanced quality control.',
	'FEATURES',
	'High gloss surface and transparency',
	'Durable ceramic top coating',
	'Improved anti-yellowing',
	'Clear coat discoloration protection',
	'Chemical and staining resistance',
	'Advanced contamination protection',
	'Scratch healing by heat-gun',
	'Resistance to high temperatures',
	'Once the film is installed, it is additionally coated with a nanoceramic coating KAVACA ION TOP COAT specifically designed to be compatible with the film and improve its performance.',
	'The new type of adhesive has a uniform structure and even distribution. It is perfectly transparent, and in addition, it has excellent adhesion and does not require the use of a primer for folds.',
	'GET KAVACA ION',
	'SUPERIOR IN EVERY ASPECT',
	'FOR PROFESSIONAL USE ONLY'
];
