import React, {FunctionComponent} from "react";
import {IButton} from "./Button.interfaces";
import classNames from "classnames";

export const Button: FunctionComponent<IButton> = ({
	children,
	onClick,
	className,
	size = 'medium',
	color= 'pink',
	suffix,
	disabled
}) => {
	const buttonClasses = classNames(`${className} button`, {
		['button--small']: size === 'small',
		['button--color-pink']: color === 'pink',
		['button--color-grey']: color === 'grey',
		['button--color-white']: color === 'white'
	})

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={buttonClasses}
		>
			<>
				<span>
					{children}
				</span>
				{suffix ? (
					<div className={'button--suffix'}>
						{suffix}
					</div>
				) : null}
			</>
		</button>
	)
};
