import {FunctionComponent, PropsWithChildren} from "react";
import {ITooltip} from "./Tooltip.interfaces";

export const Tooltip: FunctionComponent<PropsWithChildren<ITooltip>> = ({
	trigger,
	children
}) => {
	return (
		<div className={'tooltip-wrapper'}>
			{trigger}
			<div className={'tooltip-wrapper--modal'}>
				{children}
			</div>
		</div>
	);
};
