import {FunctionComponent, memo, PropsWithChildren} from "react";
import classNames from "classnames";
import {IFlex} from "./Flex.interfaces";

export const Flex: FunctionComponent<PropsWithChildren<IFlex>> = ({
	children,
	className,
	flexDirection,
	justifyContent,
	fullHeight,
	fullWidth,
	alignItems,
	ref,
	onClick
}) => {

	const computedClasses = classNames(
		'flex',
		className,
		{
			'flex--align-items-center': alignItems === 'center',
			'flex--align-items-flex-start': alignItems === 'flexStart',
			'flex--align-items-flex-end': alignItems === 'flexEnd',
			'flex--justify-content-center': justifyContent === 'center',
			'flex--justify-content-space-between': justifyContent === 'spaceBetween',
			'flex--justify-content-flex-end': justifyContent === 'flexEnd',
			'flex--justify-content-flex-start': justifyContent === 'flexStart',
			'flex--full-width': fullWidth,
			'flex--full-Height': fullHeight,
			'flex--column': flexDirection === 'column',
			'flex--row': flexDirection === 'row',
			'flex--row-reverse': flexDirection === 'rowReverse',
		}
	);

	return (
		<div ref={ref} onClick={onClick} className={computedClasses}>{children}</div>
	);
};

export default memo(Flex);
