export const PAGE_EMAIL = 'admin@admin.com';
export const PAGE_PASSWORD = 'efiE7l1%';

export const pageTabs = [
	{
		name: 'Products',
	},
	{
		name: 'Users',
	},
	{
		name: 'Promo codes'
	},
	{
		name: 'Sells'
	},
	{
		name: 'Settings'
	}
];
