import React, {
	ChangeEvent, forwardRef,
	FunctionComponent,
	LegacyRef,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {ProductWithKeys, PromoCode, PromoCodes, User, UserWithKeys, Payment} from "../ProductPage/ProductPage.interfaces";
import {API_URL, HEADERS} from "../../utils/constants";
import {Flex} from "../../components/Flex/Flex";
import {AuthContext} from "../../App";
import {Button} from "../../components/Button/Button";
import {Dialog} from "../../components/Dialog/Dialog";
import {Text} from "../../components/Text/Text";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error_icon.svg";
import { ReactComponent as CheckboxEmptyIcon } from "../../assets/icons/checkbox-empty.svg";
import { ReactComponent as CheckboxFilledIcon } from "../../assets/icons/checkbox-filled.svg";
import { ReactComponent as RadioEmptyIcon } from "../../assets/icons/radio-empty.svg";
import { ReactComponent as RadioFilledIcon } from "../../assets/icons/radio-filled.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import {ReactComponent as ChevronUpIcon} from "../../assets/icons/chevron-up.svg";
import {ReactComponent as ChevronDownIcon} from "../../assets/icons/chevron-down.svg";
import {BackgroundBlocker} from "../../components/BackgroundBlocker/BackgroundBlocker";
import {Preloader} from "../../components/Preloader/Preloader";
import {pageTabs} from "./AddProductPage.constants";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import {addHours, format} from "date-fns";
import {Switch} from "../../components/Switch/Switch";
import JSONPretty from "react-json-pretty";

const providerOptions = ['paypal', 'revolut'];

export const AddProductPage : FunctionComponent = () => {
	const [productList, setProductList] = useState<ProductWithKeys[] | undefined>(undefined);
	const [userList, setUserList] = useState<UserWithKeys[] | undefined>(undefined);
	const [codesList, setCodesList] = useState<PromoCodes[] | undefined>(undefined);
	const [sellsList, setSellsList] = useState<Payment[] | undefined>(undefined);
	const [settings, setSettings] = useState<Record<string, any>>({
		is_chat_available: false,
	});
	const [isHasAccess, setIsHasAccess] = useState(false);
	const [error, setError] = useState({
		isError: false,
		message: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isSettingLoading, setIsSettingLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [isKeyAddDialogOpen, setIsKeyAddDialogOpen] = useState(false);
	const [fileValue, setFileValue] = useState<File | undefined>(undefined);
	const [editedId, setEditedId] = useState<number | null>(null);
	const [isEventsSelectExpanded, setIsEventsSelectExpanded] = useState(false);
	const [isProviderSelectExpanded, setProviderSelectExpanded] = useState(false);
	const [deleteProductConfirmDialog, setDeleteProductConfirmDialog] = useState(false);
	const [{ authToken }] = useContext(AuthContext);
	const [resultEventInfo, setResultEventInfo] = useState<any | null>(null);
	const [loginData, setLoginData] = useState<{ email: string | null, password: string | null }>({
		email: null,
		password: null,
	});
	const [formData, setFormData] = useState<ProductWithKeys>({
		name: '',
		price: '',
		description: '',
		extra_description: '',
		order: 0,
		trial: 0,
		id: 0,
	});
	const [userFormData, setUserFormData] = useState<User>({
		name: '',
		email: '',
		password: '',
		passwordConfirm: '',
		id: 0,
	});
	const [promoCodeData, setPromoCodeData] = useState<PromoCode>({
		id: 0,
		promocode_name: '',
		amount: 0,
		comment: '',
		minPrice: 0,
		maxPrice: 0,
		usagesLeft: 0,
		oncePerUser: false,
		firstBuyOnly: false,
		expiredAt: new Date(),
	});
	const [webhookData, setWebhookData] = useState<{ url: string, provider: string, id: string }>({
		url: '',
		id: '',
		provider: 'paypal',
	});
	const [selectedEvents, setSelectedEvents] = useState<Array<boolean>>([]);
	const [eventOptions, setEventOptions] = useState<Array<string>>([]);
	const [webHookType, setWebHookType] = useState<'get' | 'create' | 'update'>('get');
	const isLoginDisabled = useMemo(() => !loginData.email && !loginData.password, [loginData]);
	const isDisabled = useMemo(() => {
		if (currentTab === 0) {
			return formData.trial === -1
				|| formData.name === ''
				|| formData.description === ''
				|| formData.price === '';
		}
		if (currentTab === 1) {
			return userFormData.name === ''
				|| userFormData.email === ''
				|| userFormData.password === ''
				|| userFormData.passwordConfirm === ''
				|| userFormData.password !== userFormData.passwordConfirm
		}
		return promoCodeData.promocode_name === ''
			|| promoCodeData.amount === 0
			|| Number(promoCodeData.maxPrice) < Number(promoCodeData.minPrice)
	}, [formData, userFormData, promoCodeData]);

	useEffect(() => {
		const FetchDataProducts = async () => {
			return await fetch(API_URL + '/api/admin/products', {headers: HEADERS, method: 'GET'});
		}
		const FetchDataUsers = async () => {
			return await fetch(API_URL + '/api/admin/users', {headers: HEADERS, method: 'GET'});
		}
		const FetchDataCodes = async () => {
			return await fetch(API_URL + '/api/admin/promocodes', {headers: HEADERS, method: 'GET'});
		}
		const FetchDataSells = async () => {
			return await fetch(API_URL + '/api/admin/payments', {headers: HEADERS, method: 'GET'});
		}
		const FetchSettings = async () => {
			return await fetch(API_URL + '/api/admin/settings', { headers: HEADERS, method: 'GET' });
		}
		const FetchEventsList = async () => {
			return await fetch(API_URL + '/api/admin/payments/paypal/events', {headers: HEADERS, method: 'GET'});
		}

		if (isHasAccess) {
			FetchDataProducts().then(response => response.json()).then(res => {
				const data = res.data as ProductWithKeys[];
				setProductList(data);
			});
			FetchDataUsers().then(response => response.json()).then(res => {
				const data = res.data;
				setUserList(data);
			});
			FetchDataCodes().then(response => response.json()).then(res => {
				const data = res.data;
				setCodesList(data);
			});
			FetchDataSells().then(response => response.json()).then(res => {
				const data = res.data;
				setSellsList(data.reverse());
			});
			FetchSettings().then(response => response.json()).then(res => {
				const data = res.data;
				setSettings(data);
			});
			FetchEventsList().then(response => response.json()).then(res => {
				const data = res.data;
				setEventOptions(data);
			});
		}
	}, [authToken, isHasAccess]);

	const toBase64 = (file: File) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

	async function getEventsList(provider: string) {
		const FetchEventsList = async () => {
			return await fetch(API_URL + `/api/admin/payments/${provider}/events`, {headers: HEADERS, method: 'GET'});
		}
		FetchEventsList().then(response => response.json()).then(res => {
			const data = res.data;
			setEventOptions(data);
		});
	}

	async function getProducts() {
		const FetchData = async () => {
			return await fetch(API_URL + '/api/admin/products', {headers: HEADERS, method: 'GET'});
		}
		FetchData().then(response => response.json()).then(res => {
			const data = res.data as ProductWithKeys[];
			setProductList(data);
		});
		setFormData({
			name: '',
			price: '',
			description: '',
			extra_description: '',
			order: 0,
			trial: 0,
			id: 0,
		})
	}

	async function getUsers() {
		const FetchDataUsers = async () => {
			return await fetch(API_URL + '/api/admin/users', {headers: HEADERS, method: 'GET'});
		}
		FetchDataUsers().then(response => response.json()).then(res => {
			const data = res.data;
			setUserList(data);
		});
		setUserFormData({
			name: '',
			email: '',
			password: '',
			passwordConfirm: '',
			id: 0,
		})
	}

	async function getCodes() {
		const FetchDataCodes = async () => {
			return await fetch(API_URL + '/api/admin/promocodes', {headers: HEADERS, method: 'GET'});
		}
		FetchDataCodes().then(response => response.json()).then(res => {
			const data = res.data;
			setCodesList(data);
		});
		setPromoCodeData({
			id: 0,
			promocode_name: '',
			amount: 0,
			comment: '',
			minPrice: 0,
			maxPrice: 0,
			usagesLeft: 0,
			oncePerUser: false,
			firstBuyOnly: false,
			expiredAt: new Date(),
		});
	}

	function handleChangeInput(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputKey: string, formName: string) {
		let inputValue = e.target.value;
		if (formName === 'products') {
			if (inputKey === 'trial' || inputKey === 'price') {
				inputValue = inputValue.replace(/\D/g, '');
			}
			setFormData(prevState => ({
				...prevState,
				[inputKey]: inputValue === 'trial' ? Number(inputValue) : inputValue,
			}));
		}
		if (formName === 'user') {
			setUserFormData(prevState => ({
				...prevState,
				[inputKey]: inputValue,
			}));
		}
		if (formName === 'promo') {
			const isSwitcher = inputKey === 'oncePerUser' || inputKey === 'firstBuyOnly'

			setPromoCodeData( prevState => ({
				...prevState,
				[inputKey]: isSwitcher ? !prevState[inputKey] : inputValue,
			}));
		}
		if (formName === 'event') {
			setWebhookData(prevState => ({
				...prevState,
				[inputKey]: inputValue,
			}));
		}
	}

	async function handleSubmitLogin() {
		setIsLoading(true);
		const LoginToAdminPanel = async () => {
			const bodyObj = {
				email: loginData.email,
				password: loginData.password,
			}
			return await fetch(
				API_URL + '/api/adm-login',
				{
					headers: {
						...HEADERS,
						'Content-Type': 'application/json'
					},
					method: 'POST',
					body: JSON.stringify(bodyObj)
				},
			);
		}
		LoginToAdminPanel().then(response => response.json()).then((res) => {
			setIsLoading(false);
			if (!res.errors) {
				setIsHasAccess(true);
			} else {
				setError({
					isError: true,
					message: 'Wrong Email or Password, please try again.'
				});
			}
		});
	}

	async function handleAddUser(userId: number) {
		setIsLoading(true);
		const modifyUser = async () => {
			const bodyObj = {
				name: userFormData.name,
				email: userFormData.email,
				password: userFormData.password,
				password_confirm: userFormData.passwordConfirm,
			}
			const computedUrl = userId ? `/api/admin/users/${userId}` : '/api/admin/users';
			return await fetch(API_URL + computedUrl, {
				headers: HEADERS,
				method: userId ? 'PUT' : 'POST',
				body: JSON.stringify(bodyObj)
			})
		}

		modifyUser().then(response => response.json()).then((res) => {
			if (!res.errors) {
				console.log('Пользователь успешно сохранен!');
				getUsers();
			} else {
				console.log('Ошибка!');
				setError({
					isError: true,
					message: res.errors.message || 'Error occurred, please try again later.',
				});
			}
		});
		setIsLoading(false);
	}

	async function handleAddProduct(productId: number) {
		setIsLoading(true);
		const modifyProduct = async () => {
			const bodyObj = {
				name: formData.name,
				description: formData.description,
				extra_description: formData.extra_description,
				price: formData.price,
				trial: formData.trial,
				order: formData.order,
			}
			const computedUrl = productId ? `/api/admin/products/${productId}` : '/api/admin/products';
			return await fetch(API_URL + computedUrl, {
				headers: HEADERS,
				method: productId ? 'PUT' : 'POST',
				body: JSON.stringify(bodyObj)
			})
		}

		modifyProduct().then(response => response.json()).then((res) => {
			if (!res.errors) {
				console.log('Товар успешно сохранен!');
				getProducts();
			} else {
				console.log('Ошибка!');
				setError({
					isError: true,
					message: res.errors.message,
				});
			}
		});
		setIsLoading(false);
	}

	async function handleAddPromocode(codeId: number) {
		setIsLoading(true);

		const modifyCode = async () => {
			const bodyObj = {
				name: promoCodeData.promocode_name,
				min_price: promoCodeData.minPrice,
				max_price: promoCodeData.maxPrice,
				comment: promoCodeData.comment,
				once_per_user: promoCodeData.oncePerUser,
				first_buy_only: promoCodeData.firstBuyOnly,
				expired_at: promoCodeData.expiredAt,
				amount: promoCodeData.amount,
				usages_left: promoCodeData.usagesLeft,
			}
			const computedUrl = codeId ? `/api/admin/promocodes/${codeId}` : '/api/admin/promocodes';
			return await fetch(API_URL + computedUrl, {
				headers: HEADERS,
				method: codeId ? 'PUT' : 'POST',
				body: JSON.stringify(bodyObj)
			})
		}

		modifyCode().then(response => response.json()).then((res) => {
			if (!res.errors) {
				console.log('Промокод успешно сохранен!');
				getCodes();
			} else {
				console.log('Ошибка!');
				setError({
					isError: true,
					message: res.errors.message || 'Error occurred, please try again later.',
				});
			}
		});
		setIsLoading(false);
	}

	function handleAddKeys(productId: number) {
		setIsKeyAddDialogOpen(true);
		setEditedId(productId);
	}

	function handleChangeUploadedFile(event: ChangeEvent<HTMLInputElement>) {
		if (event?.target?.files) {
			const fileValue = event?.target?.files[0];
			setFileValue(fileValue);
		}
	}

	function handleCancelEditProduct() {
		setFormData({
			name: '',
			price: '',
			description: '',
			extra_description: '',
			order: 0,
			trial: 0,
			id: 0,
		});
	}

	function handleCancelEditUser() {
		setUserFormData({
			name: '',
			email: '',
			password: '',
			passwordConfirm: '',
			id: 0,
		});
	}

	function handleCancelEditPromocode() {
		setPromoCodeData({
			id: 0,
			promocode_name: '',
			amount: 0,
			comment: '',
			minPrice: 0,
			maxPrice: 0,
			usagesLeft: 0,
			oncePerUser: false,
			firstBuyOnly: false,
			expiredAt: new Date(),
		});
	}

	function handleEditProduct(productId: number) {
		const editedItem = productList?.find((item) => item.id === productId);
		if (editedItem) {
			setFormData({
				name: editedItem.name,
				description: editedItem.description,
				extra_description: editedItem.extra_description,
				id: editedItem.id,
				trial: editedItem.trial,
				price: editedItem.price,
				order: editedItem.order,
			});
		}
	}

	function handleEditUser(userId: number) {
		const editedItem = userList?.find((item) => item.id === userId);
		if (editedItem) {
			setUserFormData({
				name: editedItem.name,
				email: editedItem.email,
				password: editedItem.password,
				passwordConfirm: editedItem.passwordConfirm,
				id: editedItem.id,
			});
		}
	}

	function handleEditCode(codeId: number) {
		const editedItem = codesList?.find((item) => item.id === codeId);
		if (editedItem) {
			setPromoCodeData({
				id: editedItem.id,
				promocode_name: editedItem.promocode_name || editedItem.name || '',
				amount: editedItem.amount,
				comment: editedItem.comment || '',
				minPrice: editedItem.min_price,
				maxPrice: editedItem.max_price,
				oncePerUser: editedItem.once_per_user,
				usagesLeft: editedItem.usages_left,
				firstBuyOnly: editedItem.first_buy_only,
				expiredAt: editedItem.expired_at ? new Date(editedItem.expired_at) : new Date(),
			});
		}
	}

	function handleDeleteUser(userId: number) {
		setDeleteProductConfirmDialog(true);
		setEditedId(userId);
	}

	function handleDeleteProduct(productId: number) {
		setDeleteProductConfirmDialog(true);
		setEditedId(productId);
	}

	function handleDeleteCode(codeId: number) {
		setDeleteProductConfirmDialog(true);
		setEditedId(codeId);
	}

	function handleDeleteCancel() {
		setDeleteProductConfirmDialog(false);
		setEditedId(null);
	}

	function handleChangeSettings(settingKey: string, isChecked: boolean) {
		setIsSettingLoading(true);

		const changeSettings = async (settingsObject: Record<string, any>) => {
			const bodyObj = { ...settingsObject };
			return await fetch(API_URL + '/api/admin/settings', {
				headers: HEADERS,
				method: 'PUT',
				body: JSON.stringify(bodyObj)
			})
		}

		changeSettings({ [settingKey]: isChecked }).then(response => response.json()).then(res => {
			if (!res.errors) {
				setSettings(prevState => {
					return {
						...prevState,
						[settingKey]: isChecked,
					}
				});
			}
			setIsSettingLoading(false);
		})
	}

	async function handleDeleteConfirm() {
		setIsLoading(true);
		const midUrl = currentTab === 0 ? '/api/admin/products' :  currentTab === 1 ? '/api/admin/users' : '/api/admin/promocodes';
		const computedUrl = editedId ? `${midUrl}/${editedId}` : midUrl;
		fetch(API_URL + computedUrl, {
			headers: HEADERS,
			method: 'DELETE',
		}).then(response => response.json()).then(res => {
			setIsLoading(false);
			setDeleteProductConfirmDialog(false);
			setEditedId(null);
			if (!res.errors) {
				if (currentTab === 0) getProducts();
				if (currentTab === 1) getUsers();
				if (currentTab === 2) getCodes();
			} else {
				setError({
					isError: true,
					message: res.errors.message || 'Error occurred, please try again later.',
				});
			}

		});
	}

	async function handleUploadKeys() {
		if (fileValue) {
			setIsLoading(true);
			const fileInBase64 = await toBase64(fileValue);
			const bodyObj = {
				product_id: editedId,
				file: fileInBase64,
			}
			fetch(API_URL + '/api/admin/product-keys/import', {
				headers: {
					...HEADERS,
					'Content-Type': 'application/json'
				},
				method: 'POST',
				body: JSON.stringify(bodyObj)
			}).then(response => response.json()).then(res => {
				setIsLoading(false);
				if (!res.errors) {
					setIsKeyAddDialogOpen(false);
					setEditedId(null);
				} else {
					const keyMessageError = res.errors.message || 'Keys upload error, try again later.'
					setError({
						isError: true,
						message: keyMessageError,
					});
				}
			});
		}
	}

	function handleAddEvents(e: any, index: any) {
		const newValue: Array<boolean> = [ ...selectedEvents ];
		newValue[index] = !newValue[index];
		setSelectedEvents(newValue);
	}

	async function handleChangeProvider(selectedItem: string) {
		setProviderSelectExpanded(!isProviderSelectExpanded)
		setWebhookData(prevState => ({
			...prevState,
			provider: selectedItem
		}));
		setSelectedEvents([]);
		await getEventsList(selectedItem);
	}

	async function handleClickWebhook(type: string) {
		setIsLoading(true);
		const getMethod = (t: string) => {
			if (t === 'create') return 'POST';
			if (t === 'update') return 'PUT';
			return 'GET';
		}
		const webhookId = type === 'update' ? { event_listener_id: webhookData.id } : {};
		const bodyObj = {
			url: webhookData.url,
			events: eventOptions?.filter((item, index) => selectedEvents[index]),
			...webhookId,
		}
		const body = type === 'get' ? {} : { body: JSON.stringify(bodyObj) }
		const FetchData = async () => {
			return await fetch(
				API_URL + `/api/admin/payments/${webhookData.provider}/event-listeners`,
				{
					headers: HEADERS,
					method: getMethod(type),
					...body,
				});
		}
		FetchData().then(response => response.json()).then(res => {
			const data = res.data;
			setResultEventInfo(data);
			setIsLoading(false);
		});
	}

	const renderAddKeysControls = () => {
		return (
			<Flex flexDirection={'column'}>
				<label className={'add-product-page--file'}>
					<input
						onChange={handleChangeUploadedFile}
						accept={'.xls, .xlsx'}
						type={'file'}
						placeholder={'Chose file...'}
					/>
					<span>{'Add File'}</span>
				</label>
				{fileValue && fileValue.name ? (
					<>
						<div className={'add-product-page--file-name'}>
							<Text size={'SIZE12'}>
								{fileValue.name}
							</Text>
						</div>
						<Button onClick={handleUploadKeys} size={'small'} color={'grey'}>
							{'upload'}
						</Button>
					</>
				) : null}
				<div className={'add-product-page--file-cancel'}>
					<Button onClick={() => setIsKeyAddDialogOpen(false)} size={'small'} color={'grey'}>
						{'cancel'}
					</Button>
				</div>
			</Flex>
		)
	};

	const renderTabs = () => {
		return (
			<Flex justifyContent={'flexStart'}>
				{pageTabs.map((item, index) => {
					return (
						<button
							key={`${item.name}-${index}`}
							onClick={() => setCurrentTab(index)}
							className={classNames('add-product-page--tab-links', {
								['add-product-page--tab-links--active']: index === currentTab
							})}
						>
							{item.name}
						</button>
					)
				})}
			</Flex>
		)
	}

	// @ts-ignore
	const CustomInput = forwardRef(({ value, onClick }, ref: LegacyRef<HTMLInputElement>) => (
		<div className={'add-product-page--calendar'}>
			<input ref={ref} defaultValue={value} onClick={onClick} />
			<CalendarIcon className={'add-product-page--calendar--icon'} onClick={onClick} />
		</div>
	));

	const renderCellDate = (value: Date | string | null, options?: { utc?: number, isTime: boolean }) => {
		const { utc, isTime } = options || {};
		const dateFormat = isTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
		let localValue = value;
		if (typeof value === 'string') {
			if (utc) {
				localValue = addHours(new Date(value), utc);
				return format(localValue, dateFormat);
			}
			return format(new Date(value), dateFormat);
		}
		if (utc) {
			return format(addHours(value || new Date(), utc), dateFormat);
		}
		return format(value || new Date(), dateFormat);
	};

	const renderTabContent = () => {
		if (currentTab === 0) {
			return (
				<div className={'add-product-page--tab-content'}>
					<Flex justifyContent={'spaceBetween'}>
						<div className={'add-product-page--item'}>
							<label>{'Name'}</label>
							<input value={formData.name} onChange={(e) => handleChangeInput(e, 'name', 'products')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Description'}</label>
							<input value={formData.description} onChange={(e) => handleChangeInput(e, 'description', 'products')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Order'}</label>
							<input value={formData.order} onChange={(e) => handleChangeInput(e, 'order', 'products')}  />
						</div>
					</Flex>
					<Flex justifyContent={'spaceBetween'}>
						<div className={'add-product-page--item'}>
							<label>{'Extra description'}</label>
							<input value={formData.extra_description} onChange={(e) => handleChangeInput(e, 'extra_description', 'products')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Price'}</label>
							<input value={formData.price} onChange={(e) => handleChangeInput(e, 'price', 'products')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Trial'}</label>
							<input value={formData.trial} onChange={(e) => handleChangeInput(e, 'trial', 'products')}  />
						</div>
					</Flex>
					<Flex justifyContent={'center'} className={'add-product-page--button'}>
						<Button
							onClick={() => handleAddProduct(formData.id)}
							disabled={isDisabled}
						>
							{formData.id ? 'SAVE' : 'ADD PRODUCT'}
						</Button>
						{formData.id ? (
							<Button
								onClick={handleCancelEditProduct}
								color={'grey'}
							>
								{'CANCEL'}
							</Button>
						) : null}
					</Flex>
					<div className={'add-product-page--table'}>
						<table>
							<thead>
							<tr>
								<th>{'Id'}</th>
								<th>{'Name'}</th>
								<th>{'Description'}</th>
								<th>{'Extra description'}</th>
								<th>{'Price'}</th>
								<th>{'Trial'}</th>
								<th>{'Keys'}</th>
								<th>{'Add keys'}</th>
								<th>{'Edit'}</th>
								<th>{'Delete'}</th>
							</tr>
							</thead>
							<tbody>
							{productList?.map((product) => {
								return (
									<tr key={`${product.id}-${product.name}`}>
										<td>{product.id}</td>
										<td>{product.name}</td>
										<td>
											{product.description} <br />
											Order: - {product.order}
										</td>
										<td>{product.extra_description}</td>
										<td>{product.price}</td>
										<td>{product.trial}</td>
										<td>
											Total: {product.keys_total} <br />
											Valid: {product.keys_valid} <br />
											Res'd: {product.keys_reserved} <br />
											Used: {product.keys_used} <br />
										</td>
										<td>
											<Button
												size={'small'}
												color={'grey'}
												onClick={() => handleAddKeys(product.id)}
											>
												{'Add keys'}
											</Button>
										</td>
										<td>
											<Button
												size={'small'}
												color={'grey'}
												onClick={() => handleEditProduct(product.id)}
											>
												{'Edit'}
											</Button>
										</td>
										<td>
											<Button
												size={'small'}
												color={'grey'}
												onClick={() => handleDeleteProduct(product.id)}
											>
												{'delete'}
											</Button>
										</td>
									</tr>
								)
							})}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		if (currentTab === 1) {
			return (
				<div className={'add-product-page--tab-content'}>
					<Flex className={'add-product-page--user'} justifyContent={'center'}>
						<div className={'add-product-page--item'}>
							<label>{'Name'}</label>
							<input value={userFormData.name} onChange={(e) => handleChangeInput(e, 'name', 'user')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Email'}</label>
							<input value={userFormData.email} onChange={(e) => handleChangeInput(e, 'email', 'user')}  />
						</div>
					</Flex>
					<Flex className={'add-product-page--user'} justifyContent={'center'}>
						<div className={'add-product-page--item'}>
							<label>{'Password'}</label>
							<input value={userFormData.password} onChange={(e) => handleChangeInput(e, 'password', 'user')}  />
						</div>
						<div className={'add-product-page--item'}>
							<label>{'Password confirm'}</label>
							<input value={userFormData.passwordConfirm} onChange={(e) => handleChangeInput(e, 'passwordConfirm', 'user')}  />
						</div>
					</Flex>
					<Flex justifyContent={'center'} className={'add-product-page--button'}>
						<Button
							onClick={() => handleAddUser(userFormData.id)}
							disabled={isDisabled}
						>
							{userFormData.id ? 'SAVE' : 'ADD USER'}
						</Button>
						{userFormData.id ? (
							<Button
								onClick={handleCancelEditUser}
								color={'grey'}
							>
								{'CANCEL'}
							</Button>
						) : null}
					</Flex>
					<div className={'add-product-page--table'}>
						<table>
							<thead>
								<tr>
									<th>{'Id'}</th>
									<th>{'Name'}</th>
									<th>{'Email'}</th>
									<th>{'User data'}</th>
									<th>{'Edit'}</th>
									<th>{'Delete'}</th>
								</tr>
							</thead>
							<tbody>
							{userList?.map((user) => {
								return (
									<tr key={`${user.id}-${user.name}`}>
										<td>{user.id}</td>
										<td>{user.name}</td>
										<td>{user.email}</td>
										<td>
											Is admin: {user.is_admin} <br />
											Is trial available: {user.is_trial_available} <br />
											Mobile: {user.mobile} <br />
										</td>
										<td>
											<Button
												size={'small'}
												color={'grey'}
												onClick={() => handleEditUser(user.id)}
											>
												{'Edit'}
											</Button>
										</td>
										<td>
											<Button
												size={'small'}
												color={'grey'}
												onClick={() => handleDeleteUser(user.id)}
											>
												{'Delete'}
											</Button>
										</td>
									</tr>
								)
							})}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		if (currentTab === 2) {
			return (
				<div className={'add-product-page--tab-content'}>
					<Flex justifyContent={'spaceBetween'}>
						<Flex className={'add-product-page--promo-code'} justifyContent={'center'}>
							<div className={'add-product-page--item add-product-page--item--half'}>
								<div className={'add-product-page--section'}>
									<label>{'Code name'}</label>
									<input value={promoCodeData.promocode_name} onChange={(e) => handleChangeInput(e, 'promocode_name', 'promo')}  />
								</div>
								<div className={'add-product-page--section'}>
									<label>{'Min price'}</label>
									<input value={promoCodeData.minPrice} onChange={(e) => handleChangeInput(e, 'minPrice', 'promo')}  />
								</div>
							</div>
							<div className={'add-product-page--item add-product-page--item--half'}>
								<div className={'add-product-page--section'}>
									<label>{'Discount %'}</label>
									<input value={promoCodeData.amount} onChange={(e) => handleChangeInput(e, 'amount', 'promo')}  />
								</div>
								<div className={'add-product-page--section'}>
									<label>{'Max price'}</label>
									<input value={promoCodeData.maxPrice} onChange={(e) => handleChangeInput(e, 'maxPrice', 'promo')}  />
								</div>
							</div>
						</Flex>
						<Flex className={'add-product-page--user'} justifyContent={'center'}>
							<div className={'add-product-page--item add-product-page--item--full'}>
								<div className={'add-product-page--section'}>
									<label>{'Comment'}</label>
									<input value={promoCodeData.comment} onChange={(e) => handleChangeInput(e, 'comment', 'promo')}  />
								</div>
								<div className={'add-product-page--section'}>
									<label>{'Usages amount'}</label>
									<input value={promoCodeData.usagesLeft} onChange={(e) => handleChangeInput(e, 'usagesLeft', 'promo')}  />
								</div>
							</div>
						</Flex>
						<Flex className={'add-product-page--user'} justifyContent={'center'}>
							<div className={'add-product-page--item add-product-page--item--full'}>
								<div className={'add-product-page--section'}>
									<label>{'Active until'}</label>
									<DatePicker
										selected={promoCodeData.expiredAt}
										dateFormat={'dd/MM/yyyy'}
										onChange={(date: Date) => setPromoCodeData( prevState => ({
											...prevState,
											expiredAt: date,
										}))}
										customInput={<CustomInput />}
									/>
								</div>
								<div className={'add-product-page--section'}>
									<div>
										<label className={classNames('add-product-page--modal--item', {
											['add-product-page--modal--item--disabled']: Boolean(promoCodeData.id)
										})}>
											<Flex justifyContent={'flexStart'} alignItems={'center'}>
												<input
													onChange={(e) => handleChangeInput(e, 'oncePerUser', 'promo')}
													type={'checkbox'}
													disabled={Boolean(promoCodeData.id)}
													className={'add-product-page--checkbox'}
												/>
												{promoCodeData.oncePerUser
													? <CheckboxFilledIcon className={'add-product-page--checkbox--icon'} />
													: <CheckboxEmptyIcon className={'add-product-page--checkbox--icon'} />
												}
												<span className={'add-product-page--checkbox--text'}>
													{'Once per user'}
												</span>
											</Flex>
										</label>
									</div>
									<div>
										<label className={classNames('add-product-page--modal--item', {
											['add-product-page--modal--item--disabled']: Boolean(promoCodeData.id)
										})}>
											<Flex justifyContent={'flexStart'} alignItems={'center'}>
												<input
													onChange={(e) => handleChangeInput(e, 'firstBuyOnly', 'promo')}
													type={'checkbox'}
													disabled={Boolean(promoCodeData.id)}
													className={'add-product-page--checkbox'}
												/>
												{promoCodeData.firstBuyOnly
													? <CheckboxFilledIcon className={'add-product-page--checkbox--icon'} />
													: <CheckboxEmptyIcon className={'add-product-page--checkbox--icon'} />
												}
												<span className={'add-product-page--checkbox--text'}>
													{'First buy only'}
												</span>
											</Flex>
										</label>
									</div>
								</div>
							</div>
						</Flex>
					</Flex>
					<Flex justifyContent={'center'} className={'add-product-page--button'}>
						<Button
							onClick={() => handleAddPromocode(promoCodeData.id)}
							disabled={isDisabled}
						>
							{promoCodeData.id ? 'SAVE' : 'ADD CODE'}
						</Button>
						{promoCodeData.id ? (
							<Button
								onClick={handleCancelEditPromocode}
								color={'grey'}
							>
								{'CANCEL'}
							</Button>
						) : null}
					</Flex>
					<div className={'add-product-page--table'}>
						<table>
							<thead>
								<tr>
									<th>{'Id'}</th>
									<th>{'Code'}</th>
									<th>{'Discount'}</th>
									<th>{'Range'}</th>
									<th>{'Comment'}</th>
									<th>{'Active Until'}</th>
									<th>{'Usages left'}</th>
									<th>{'Edit'}</th>
									<th>{'Delete'}</th>
								</tr>
							</thead>
							<tbody>
								{codesList?.map((codes) => {
									return (
										<tr key={`${codes.id}-${codes.promocode_name}-${codes.name}`}>
											<td>{codes.id}</td>
											<td>{codes.promocode_name || codes.name}</td>
											<td>{codes.amount}</td>
											<td>{codes.min_price} - {codes.max_price}</td>
											<td>{codes.comment}</td>
											<td>{renderCellDate(codes.expired_at)}</td>
											<td>{codes.usages_left}</td>
											<td>
												<Button
													size={'small'}
													color={'grey'}
													onClick={() => handleEditCode(codes.id)}
												>
													{'Edit'}
												</Button>
											</td>
											<td>
												<Button
													size={'small'}
													color={'grey'}
													onClick={() => handleDeleteCode(codes.id)}
												>
													{'Delete'}
												</Button>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		if (currentTab === 3) {
			return (
				<div className={'add-product-page--tab-content'}>
					<div className={'add-product-page--table'}>
						<table>
							<thead>
								<tr>
									<th>{'Id'}</th>
									<th>{'Country'}</th>
									<th>{'Name'}</th>
									<th>{'Date'}</th>
									<th>{'Product Name'}</th>
									<th>{'Key'}</th>
									<th>{'Price'}</th>
									<th>{'Promocode'}</th>
								</tr>
							</thead>
							<tbody>
							{sellsList?.map((payment, index) => {
								return (
									<tr key={`${payment.id}-${payment.payer_name}-${index}`}>
										<td>{payment.id}</td>
										<td>{payment.user?.customer?.country_name || ''}</td>
										<td>
											<>
												{payment.payer_name}
												<br />
												{payment.payer_email}
											</>
										</td>
										<td>{renderCellDate(payment.created_at, { isTime: true })}</td>
										<td>{payment.product_name}</td>
										<td>{payment.product_key_serial}</td>
										<td>
											<>
												{payment.amount} USD
												<br />
												{payment.provider}
											</>
										</td>
										<td>{payment.promocode?.full_name || ''}</td>
									</tr>
								)
							})}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		if (currentTab === 4) {
			const statusColor = () => {
				if (isSettingLoading) return 'white';
				if (settings?.is_chat_available) return 'green';
				return 'pink';
			}

			const statusText = () => {
				if (isSettingLoading) return 'Loading...';
				if (settings?.is_chat_available) return 'Active';
				return 'OFF';
			}

			return (
				<div className={'add-product-page--tab-content'}>
					<Flex className={'add-product-page--settings-wrapper'} justifyContent={'flexStart'} alignItems={'center'}>
						<Text className={'add-product-page--settings-text'} color={'white'} size={'SIZE24'} font={'book'}>
							{'AI Assistant'}
						</Text>
						<Switch
							value={settings?.is_chat_available}
							onChange={(checked: boolean) => handleChangeSettings('is_chat_available', checked)}
						/>
					</Flex>
					<Flex flexDirection={'column'} justifyContent={'flexStart'} alignItems={'flexStart'}>
						<Text className={'add-product-page--settings-text'} color={'white'} size={'SIZE14'} font={'book'}>
							{'Status'}
						</Text>
						<Text
							className={'add-product-page--settings-text'}
							color={statusColor()}
							size={'SIZE18'}
							font={'book'}
						>
							{statusText()}
						</Text>
					</Flex>
					<Flex className={'add-product-page--settings-hook'} justifyContent={'flexStart'} alignItems={'center'}>
						<Text className={'add-product-page--settings-text'} color={'white'} size={'SIZE24'} font={'book'}>
							{'Web-hooks'}
						</Text>
					</Flex>
					<Flex className={'add-product-page--settings-hook--header'}>
						<div className={'add-product-page--settings-hook--part'}>
							<label className={classNames('add-product-page--modal--item')}>
								<Flex justifyContent={'flexStart'} alignItems={'center'}>
									<input
										onChange={() => setWebHookType('get')}
										type={'radio'}
										name={'hook-type'}
										className={'add-product-page--checkbox'}
									/>
									{webHookType === 'get'
										? <RadioFilledIcon className={'add-product-page--checkbox--radio'} />
										: <RadioEmptyIcon className={'add-product-page--checkbox--radio'} />
									}
									<span className={'add-product-page--checkbox--text'}>
										{'Get list'}
									</span>
								</Flex>
							</label>
						</div>
						<div className={'add-product-page--settings-hook--part'}>
							<label className={classNames('add-product-page--modal--item')}>
								<Flex justifyContent={'flexStart'} alignItems={'center'}>
									<input
										onChange={() => setWebHookType('update')}
										type={'radio'}
										name={'hook-type'}
										className={'add-product-page--checkbox'}
									/>
									{webHookType === 'update'
										? <RadioFilledIcon className={'add-product-page--checkbox--radio'} />
										: <RadioEmptyIcon className={'add-product-page--checkbox--radio'} />
									}
									<span className={'add-product-page--checkbox--text'}>
										{'Update'}
									</span>
								</Flex>
							</label>
						</div>
						<div className={'add-product-page--settings-hook--part'}>
							<label className={classNames('add-product-page--modal--item')}>
								<Flex justifyContent={'flexStart'} alignItems={'center'}>
									<input
										onChange={() => setWebHookType('create')}
										type={'radio'}
										name={'hook-type'}
										className={'add-product-page--checkbox'}
									/>
									{webHookType === 'create'
										? <RadioFilledIcon className={'add-product-page--checkbox--radio'} />
										: <RadioEmptyIcon className={'add-product-page--checkbox--radio'} />
									}
									<span className={'add-product-page--checkbox--text'}>
										{'Create'}
									</span>
								</Flex>
							</label>
						</div>
					</Flex>
					<div className={'add-product-page--setting-params'}>
						<div className={'add-product-page--item'}>
							<div className={'add-product-page--section add-product-page--select'}>
								<label>{'Provider'}</label>
								<input
									value={webhookData.provider}
									readOnly
									onClick={() => setProviderSelectExpanded(!isProviderSelectExpanded)}
								/>
								<div className={'add-product-page--prefix'}>
									{ isProviderSelectExpanded
										? <ChevronUpIcon />
										: <ChevronDownIcon />
									}
								</div>
								{ isProviderSelectExpanded ? (
									<div className={'add-product-page--modal'}>
										{providerOptions?.map((item, index) => {
											return (
												<div key={index} className={'add-product-page--modal--item--root'}>
													<label className={'add-product-page--modal--item'}>
														<Flex justifyContent={'flexStart'} alignItems={'center'}>
															<input
																onChange={() => handleChangeProvider(item)}
																type={'checkbox'}
																className={'add-product-page--checkbox'}
															/>
															<span className={classNames('add-product-page--checkbox--modal-text', {
																['add-product-page--checkbox--modal-text--bold']: webhookData.provider === item
															})}>
																{item}
															</span>
														</Flex>
													</label>
												</div>
											);
										})}
									</div>
								) : null }
							</div>
							{webHookType !== 'get' ? (
								<>
									<div className={'add-product-page--section'}>
										<label>{'Url'}</label>
										<input value={webhookData.url} onChange={(e) => handleChangeInput(e, 'url', 'event')}  />
									</div>
									{webHookType === 'update' ? (
										<div className={'add-product-page--section'}>
											<label>{'Webhook ID'}</label>
											<input value={webhookData.id} onChange={(e) => handleChangeInput(e, 'id', 'event')}  />
										</div>
									) : null}
									<div className={'add-product-page--section add-product-page--select'}>
										<label>{'Events'}</label>
										<input
											value={eventOptions?.filter((item, index) => selectedEvents[index]).join(',')}
											readOnly
											onClick={() => setIsEventsSelectExpanded(!isEventsSelectExpanded)}
										/>
										<div className={'add-product-page--prefix'}>
											{ isEventsSelectExpanded
												? <ChevronUpIcon />
												: <ChevronDownIcon />
											}
										</div>
										{ isEventsSelectExpanded ? (
											<div className={'add-product-page--modal'}>
												{eventOptions?.map((item, index) => {
													return (
														<div key={index} className={'add-product-page--modal--item--root'}>
															<label className={'add-product-page--modal--item'}>
																<Flex justifyContent={'flexStart'} alignItems={'center'}>
																	<input onChange={(e) => handleAddEvents(e, index)} type={'checkbox'} className={'add-product-page--checkbox'} />
																	{selectedEvents[index]
																		? <CheckboxFilledIcon className={'add-product-page--checkbox--modal-icon'} />
																		: <CheckboxEmptyIcon className={'add-product-page--checkbox--modal-icon'} />
																	}
																	<span className={classNames('add-product-page--checkbox--modal-text', {
																		['add-product-page--checkbox--modal-text--bold']: selectedEvents[index]
																	})}>
																	{item}
																</span>
																</Flex>
															</label>
														</div>
													);
												})}
											</div>
										) : null }
									</div>
								</>
							) : null}
						</div>
						<Flex justifyContent={'flexStart'} className={'add-product-page--item'}>
							<div className={'add-product-page--action'}>
								<Button size={'small'} onClick={() => handleClickWebhook(webHookType)}>
									{'Send'}
								</Button>
							</div>
						</Flex>
						{resultEventInfo ? (
							<Flex justifyContent={'flexStart'} className={'add-product-page--item add-product-page--item--wide'}>
								<div className={'add-product-page--data'}>
								<span className={'add-product-page--item--span'}>
									<JSONPretty
										id="json-pretty"
										data={resultEventInfo}
									/>
								</span>
								</div>
							</Flex>
						) : null}
					</div>
				</div>
			)
		}
		return null
	};

	return (
		<div className={'add-product-page'}>
			{!isHasAccess ? (
				<Flex className={'add-product-page--login'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
					<div className={'add-product-page--item'}>
						<label>{'Email'}</label>
						<input name={'name'} id={'name'} type={'text'} onChange={(e) => setLoginData(prevState => ({...prevState, email: e.target.value}))} />
					</div>
					<div className={'add-product-page--item'}>
						<label>{'Password'}</label>
						<input name={'password'} id={'password'} type={'password'} onChange={(e) => setLoginData(prevState => ({...prevState, password: e.target.value}))} />
					</div>
					<div className={'add-product-page--item'}>
						<Button disabled={isLoginDisabled} size={'small'} onClick={handleSubmitLogin}>{'Log in'}</Button>
					</div>
				</Flex>
			) : (
				<>
					{renderTabs()}
					{renderTabContent()}
				</>
			)}
			<Dialog
				isOpen={isKeyAddDialogOpen}
				renderControls={renderAddKeysControls}
			/>
			<Dialog
				isOpen={deleteProductConfirmDialog}
				icon={<ErrorIcon />}
				message={`Are you sure you want to remove this ${currentTab === 0 ? 'product' : 'user'}?`}
				renderControls={() => (
					<div className={'add-product-page--button'}>
						<Button onClick={handleDeleteConfirm} size={'small'} color={'pink'}>
							{'Delete'}
						</Button>
						<Button onClick={handleDeleteCancel} size={'small'} color={'grey'}>
							{'Cancel'}
						</Button>
					</div>
				)}
			/>
			<Dialog
				isOpen={error.isError}
				icon={<ErrorIcon />}
				message={error.message}
				renderControls={() => (
					<div className={'add-product-page--file-cancel'}>
						<Button onClick={() => setError({ isError: false, message: ''})} size={'small'} color={'grey'}>
							{'Ок'}
						</Button>
					</div>
				)}
			/>
			{isLoading ? (
				<BackgroundBlocker>
					<Preloader />
				</BackgroundBlocker>
			) : null}
		</div>
	)
}
