import React, {Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {API_URL, HEADERS} from "../../utils/constants";
import {Text} from "../../components/Text/Text";
import {Product} from "./ProductPage.interfaces";
import {IS_SERVICE_MESSAGE, PRODUCTS_CONFIG, SERVICE_MESSAGE, TEXT_MOCK} from "./ProductPage.constants";
import classNames from "classnames";
import {Flex} from "../../components/Flex/Flex";
import {useSearchParams} from "react-router-dom";
import { ReactComponent as CreditCard } from "../../assets/icons/credit_card.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success_icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error_icon.svg";
import { ReactComponent as RadioEmptyIcon } from "../../assets/icons/radio-empty.svg";
import { ReactComponent as RadioFilledIcon } from "../../assets/icons/radio-filled.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import {Preloader} from "../../components/Preloader/Preloader";
import {Dialog} from "../../components/Dialog/Dialog";
import {BackgroundBlocker} from "../../components/BackgroundBlocker/BackgroundBlocker";
import {Button} from "../../components/Button/Button";
import {AuthContext} from "../../App";
import {BackButton} from "../../components/BackButton/BackButton";
import {ReactComponent as CheckboxFilledIcon} from "../../assets/icons/checkbox-filled.svg";
import {ReactComponent as CheckboxEmptyIcon} from "../../assets/icons/checkbox-empty.svg";
import {Tooltip} from "../../components/Tooltip/Tooltip";

let intervalFunction: any;

export const ProductPage : FunctionComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [{ authToken, userInfo, fetchUserData }] = useContext(AuthContext);
	const [productList, setProductList] = useState<Product[] | undefined>(undefined);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isPaymentDialogOpen, setPaymentIsDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState({ isOpen: false, message: '' })
	const [isSuccess, setIsSuccess] = useState({ isOpen: false, message: '' });
	const [isPaymentAvailable, setIsPaymentAvailable] = useState( { isOpen: false, productId: 0 });
	const [isHintShow, setIsHintShow] = useState(false);
	const [isSendToEmail, setIsSendToEmail] = useState(false);
	const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
	const paymentStatus = searchParams.get('payment_status');
	const [promocodeValue, setPromocodeValue] = useState('');
	const [promocodeError, setPromocodeError] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('credit');
	const [discountParams, setDiscountParams] = useState({
		isError: false,
		data: {
			discount: '',
			oldPrice: '',
			newPrice: '',
		}
	});
	const [billingAddress, setBillingAddress] = useState<Record<string, string> | undefined>(undefined);
	const [billingAddressSaved, setBillingAddressSaved] = useState(false);
	const [vatError, setVatError] = useState(false);
	const headerWithToken = useMemo(() => {
		return {
			...HEADERS,
			'Authorization': `Bearer ${authToken}`
		}
	}, [authToken]);

	const cutTrialFromProduct = useCallback(() => {
		setProductList(prevState => {
			let newArray = prevState;
			if (prevState) {
				newArray = [...prevState].filter((prod) => prod.name !== 'Trial');
			}
			return newArray;
		});
	}, []);

	useEffect(() => {
		if (userInfo && !billingAddress) {
			setBillingAddress(userInfo?.data?.customer?.billing_address);
		}
	}, [userInfo,  billingAddress]);

	useEffect(() => {
		if (billingAddressSaved) {
			setTimeout(() => {
				setBillingAddressSaved(false);
			}, 3000);
		}
	}, [billingAddressSaved]);

	useEffect(() => {
		if (
			userInfo &&
			userInfo?.data?.is_trial_available === 0 &&
			productList &&
			productList.find((p) => p.name === 'Trial')) {
			cutTrialFromProduct();
		}
	}, [userInfo, cutTrialFromProduct, productList]);

	useEffect(() => {
		const FetchData = async () => {
			return await fetch(API_URL + '/api/products', {
				headers: headerWithToken,
				method: 'GET'
			});
		}

		if (!productList && authToken) {
			FetchData().then(response => response.json()).then(res => {
				const data = res.data as Product[];
				setProductList(data);
			})
		}
	});

	useEffect(() => {
		if (paymentStatus === 'COMPLETED') {
			setPaymentIsDialogOpen(true);
		}
	}, [paymentStatus]);

	function handleChangeBillingAddress(key: string, value: string) {
		setBillingAddress(prevState => {
			return {
				...prevState,
				[key]: value
			}
		})
	}

	function handleHintShow() {
		setIsHintShow(true);
	}

	function checkIsPaymentSuccess(orderId: number, provider: string) {
		const checkPayment = async () => {
			return await fetch(API_URL + `/api/payments/${provider}/order/${orderId}/state`, {
				headers: headerWithToken,
				method: 'GET'
			});
		}

		intervalFunction = setInterval(async () => {
			checkPayment().then(response => response.json()).then(res => {
				if (res.data.state === 'completed' || res.data.state === 'failed') {
					clearInterval(intervalFunction);
					setIsPaymentProcessing(false);
				}
				if (res.data.state === 'failed') {
					setIsError({ isOpen: true, message: res.errors })
				}
				if (res.data.state === 'completed') {
					setPaymentIsDialogOpen(true);
				}
			});
		}, 2000);
	}

	function handleClosePaymentDialog() {
		searchParams.delete('payment_status');
		searchParams.delete('email');
		setSearchParams(searchParams);
		setPaymentIsDialogOpen(false);
	}

	function handleProceedToPayment() {
		const product = productList?.find((i) => i.id === isPaymentAvailable.productId);
		if (product) {
			const promocode = promocodeValue && discountParams.data.discount ? { promocode_name: promocodeValue } : undefined;
			const isCredit = paymentMethod === 'credit'
			const selectedProvider = isCredit ? 'revolut' : 'paypal';
			const bodyObj = {
				is_card: Boolean(isCredit),
				product_id: product.id || null,
				...promocode,
			};
			if (bodyObj.product_id) {
				setIsLoading(true);
				const SendToPayPall = async () => {
					return await fetch(API_URL +`/api/payments/${selectedProvider}`, {
						headers: headerWithToken,
						method: 'POST',
						body: JSON.stringify(bodyObj)
					});
				}
				SendToPayPall().then(response => response.json()).then(res => {
					if (!res.errors) {
						const target = isCredit ? "_blank" : "_self";
						setIsLoading(false);
						if (isCredit) {
							setIsPaymentProcessing(true);
							checkIsPaymentSuccess(res?.data?.id, selectedProvider);
							handleCancelPayment();
						}
						window.open(res?.data?.url, target);
					} else {
						setIsLoading(false);
						setIsError({ isOpen: true, message: res.errors })
					}
				})
			} else {
				setIsError({ isOpen: true, message: TEXT_MOCK[6] });
				setIsPaymentProcessing(false);
			}
		}
	}

	function handlePromoCodeCancel() {
		setDiscountParams({
			isError: false,
			data: {
				discount: '',
				oldPrice: '',
				newPrice: '',
			}
		});
		setPromocodeError('');
		setPromocodeValue('');
	}

	async function handlePromoCodeRedeem() {
		setIsLoading(true);
		setPromocodeError('');
		const bodyObj = {
			promocode_name: promocodeValue,
			product_id: isPaymentAvailable.productId,
		};
		const PromoCodeRedeem = async () => {
			return await fetch(API_URL + '/api/promocodes/redeem', {
				headers: headerWithToken,
				method: 'POST',
				body: JSON.stringify(bodyObj)
			});
		}
		PromoCodeRedeem().then(response => response.json()).then(res => {
			if (res.status === 'success' && res.data) {
				setDiscountParams({
					isError: false,
					data: {
						discount: res.data.discount,
						oldPrice: res.data.old_price,
						newPrice: res.data.new_price,
					}
				});
			} else {
				setDiscountParams( prevState => {
					return {
						...prevState,
						isError: true,
					}
				})
				if (typeof res.errors === 'string') {
					setPromocodeError(res.errors);
				} else {
					setPromocodeError(res?.errors?.promocode_name?.[0] || 'Promocode unknown error');
				}

				setPromocodeValue('');
			}
			setIsLoading(false);
		});
	}

	async function handleSendTrial() {
		setIsLoading(true);
		const SendActivateTrail = async () => {
			return await fetch(API_URL + '/api/products/trial', { headers: headerWithToken, method: 'POST' });
		}
		SendActivateTrail().then(response => response.json()).then(res => {
			setIsLoading(false);
			setIsDialogOpen(false);
			if (!res.errors) {
				setIsSuccess({ isOpen: true, message: TEXT_MOCK[5] });
				cutTrialFromProduct();
			} else {
				setIsError({ isOpen: true, message: res.errors });
			}
		});
	}

	async function handleSaveBillingAddress() {
		setIsLoading(true);
		setVatError(false);
		const { company_name, address, city, country_name, extra, postal_code, vat_number: vat } = billingAddress || {};
		const vat_number = vat && vat.length ? { vat_number: vat } : {};
		const bodyObj = {
			company_name,
			country_name,
			postal_code,
			address,
			city,
			extra,
			...vat_number,
		};
		const SaveBillingAddress = async () => {
			return await fetch(API_URL + `/api/billing-addresses/${billingAddress?.id}`, {
				headers: headerWithToken,
				method: 'PUT',
				body: JSON.stringify(bodyObj)
			});
		}
		SaveBillingAddress().then(response => response.json()).then(async res => {
			setIsLoading(false);
			if (!res.errors) {
				await fetchUserData?.(authToken || '');
				setBillingAddressSaved(true);
			} else {
				if (res.errors?.vat_number) {
					setVatError(true);
					setBillingAddress(prevState => {
						return {
							...prevState,
							vat_number: userInfo?.data?.customer?.billing_address?.vat_number || '',
						}
					});
				} else {
					setIsError({ isOpen: true, message: 'Error saving billing address, try again later.' });
				}
			}
		});
	}

	function changePaymentMethod(type: string) {
		setPaymentMethod(type);
	}

	async function handleCancelPayment() {
		setIsPaymentAvailable({ isOpen: false, productId: 0 });
		handlePromoCodeCancel();
		//const CancelPayment = async () => {
		//	return await fetch(API_URL + '/api/order/cancel', {
		//		headers: headerWithToken,
		//		method: 'POST',
		//	});
		//}
		//CancelPayment().then(response => response.json()).then(res => {
		//	setIsLoading(false);
		//	if (!res.errors) {
		//
		//	} else {
		//		setIsError({ isOpen: true, message: res.errors });
		//	}
		//})
	}

	function handleCancelAwaitPayment() {
		setIsPaymentProcessing(false);
		clearInterval(intervalFunction);
	}

	async function handleProductClick(productName: string, productIndex: number) {
		if (productName === 'Trial') {
			setIsDialogOpen(true);
		} else {
			if (IS_SERVICE_MESSAGE) {
				setIsError({
					isOpen: true,
					message: SERVICE_MESSAGE
				});
			} else {
				setIsPaymentAvailable({ isOpen: true, productId: productList?.[productIndex].id || 0 });
			}
		}
	}

	function calcPrice(isEurope: boolean, price: string | number | undefined, skip: boolean) {
		if (!isEurope || !price || skip) return price;
		const numericPrice = typeof price === 'string' ? Number(price) : price;
		return Number(numericPrice + (numericPrice * 0.19)).toFixed(2);
	}

	const renderProducts = () => {
		if (!productList) {
			return (
				<>
					<Flex
						justifyContent={'spaceBetween'}
						className={'products-page--product-wrapper'}
					>
						<Text color={'white'} font={'black'} size={'SIZE30'}>{'Loading...'}</Text>
					</Flex>
					<BackgroundBlocker>
						<Preloader />
					</BackgroundBlocker>
				</>
			)
		}
		let accuracy = 0;
		const products = productList.map((productItem, productIndex) => {
			const isTrial = productIndex === 0 && productItem.name === 'Trial';
			if (isTrial) {
				accuracy = 1;
			}
			const getProductProps = (ind: number) => {
				if (isTrial) {
					return {
						size: 'full',
						bgColor: 'pink',
						discount: null,
						group: '',
					}
				}
				return PRODUCTS_CONFIG[ind] || {
					size: 'full',
					bgColor: 'white',
					discount: null,
					group: '',
				};
			}
			const productProps = getProductProps(productIndex - accuracy);
			const isWhite = productProps.bgColor === 'white';
			const isFullSize = productProps.size === 'full';
			const setFontSize = () => {
				if (isTrial) return 'SIZE36';
				if (isFullSize) return 'SIZE22';
				return 'SIZE36';
			};
			const computedBgColors = () => {
				return {
					'products-page--item--grey': !isWhite,
					'products-page--item--white': isWhite,
					'products-page--item--pink': productProps.bgColor === 'pink',
				}
			};
			const renderDetailsPrice = () => {
				if (isTrial) {
					return (
						<>
							<Text
								color={'white'}
								font={'black'}
								size={setFontSize()}
								transform={'uppercase'}
							>
								{'TWO WEEK ACCESS'}
							</Text>
							<Text
								className={'join-us-page--puzzle--item--text'}
								transform={'uppercase'}
								color={'white'}
								font={'black'}
								size={'SIZE14'}
							>
								{'25 square meters, 3 AI AUTO-LAYOUTS'}
							</Text>
						</>
					)
				}
				return (
					<>
						<Text
							color={isWhite ? 'black' : 'white'}
							font={'black'}
							size={'SIZE36'}
							transform={isFullSize ? 'uppercase' : undefined}
						>
							{`$${productItem.price}`}
						</Text>
						{productProps.discount !== null ? (
							<Text
								className={'join-us-page--puzzle--item--text'}
								transform={'uppercase'}
								color={isWhite ? 'black' : 'white'}
								font={'black'}
								size={'SIZE14'}
							>
								{`${productProps.discount}% off`}
							</Text>
						) : null}
					</>
				)
			}
			return (
				<Fragment key={`${productItem.name}-${productIndex}`}>
					{productProps.group ? (
						<Text
							className={'products-page--full-text'}
							color={'white'}
							font={'book'}
							size={'SIZE14'}
							transform={'uppercase'}
						>
							{productProps.group}
						</Text>
					) : null}
					<Flex
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
						onClick={() => handleProductClick(productItem.name, productIndex)}
						className={classNames('products-page--item', {
							'products-page--item--half': !isFullSize,
							'products-page--item--full': isFullSize,
							...computedBgColors(),
						})}
					>
						<Text
							className={'products-page--item--sub-bold'}
							color={isWhite ? 'black' : 'white'}
							font={'black'}
							size={setFontSize()}
							transform={isFullSize ? 'uppercase' : undefined}
						>
							{productItem.name}
						</Text>
						{!isTrial ? (
							<Text
								className={'join-us-page--puzzle--item--text'}
								transform={'uppercase'}
								color={isWhite ? 'black' : 'white'}
								font={'black'}
								size={'SIZE14'}
							>
								{productItem.description}
							</Text>
						) : null}
						<Flex
							justifyContent={'center'}
							alignItems={'center'}
							flexDirection={'column'}
							className={classNames('products-page--item--details', {...computedBgColors()})}
						>
							{renderDetailsPrice()}
						</Flex>
					</Flex>
				</Fragment>
			)
		});
		return (
			<>
				<Flex justifyContent={'spaceBetween'} className={'products-page--product-wrapper'}>
					{products}
				</Flex>
			</>
		)
	}

	const renderDialog = () => {
		const isOpen = isDialogOpen ||
			isPaymentDialogOpen ||
			isError.isOpen ||
			isSuccess.isOpen ||
			isPaymentAvailable.isOpen ||
			isHintShow ||
			isPaymentProcessing ||
			false;

		const getDialogProps = () => {
			let dialogMessage;
			let renderControls;
			let renderBody;

			if (isError.isOpen) {
				dialogMessage = isError.message;
				renderControls = () => {
					return (
						<div className={'products-page--button-between'}>
							<Button
								onClick={() => setIsError({ isOpen: false, message: '' })}
								color={'grey'}
								size={'small'}
							>
								{'OK'}
							</Button>
						</div>
					)
				}
			} else if (isPaymentProcessing) {
				dialogMessage = 'Payment in process...';
				renderControls = () => {
					return (
						<div className={'products-page--button-between'}>
							<Button
								onClick={() => handleCancelAwaitPayment()}
								color={'grey'}
								size={'small'}
							>
								{'Cancel'}
							</Button>
						</div>
					)
				}
			} else if (isSuccess.isOpen) {
				dialogMessage = isSuccess.message;
				renderControls = () => {
					return (
						<div className={'products-page--button-between'}>
							<Button
								onClick={() => setIsSuccess({ isOpen: false, message: '' })}
								color={'grey'}
								size={'small'}
							>
								{'OK'}
							</Button>
						</div>
					)
				}
			} else if (isPaymentDialogOpen) {
				dialogMessage = `${TEXT_MOCK[2]} -\n ${userInfo?.data?.email}`;
				renderControls = () => {
					return (
						<div className={'products-page--button-between'}>
							<Button
								onClick={handleClosePaymentDialog}
								color={'grey'}
								size={'small'}
							>
								{'OK'}
							</Button>
						</div>
					)
				}
			} else if (isDialogOpen) {
				dialogMessage = TEXT_MOCK[0];
				renderControls = () => {
					return (
						<div className={'products-page--button-between'}>
							<Button onClick={handleSendTrial} size={'small'}>{'OK'}</Button>
							<div/>
							<Button onClick={() => setIsDialogOpen(false)} color={'grey'} size={'small'}>{'LATER'}</Button>
						</div>
					)
				}
			} else if (isPaymentAvailable.isOpen) {
				dialogMessage = '';
				renderControls = () => {
					return (
						<Flex
							className={'products-page--payment-dialog'}
							fullWidth
							alignItems={'flexStart'}
							justifyContent={'flexStart'}
							flexDirection={'row'}
						>
							<div className={'products-page--payment-side'}>
								<Text font={'book'} size={'SIZE14'}>
									{'Choose your payment method:'}
								</Text>
								<Flex flexDirection={'column'} fullWidth alignItems={'flexStart'} justifyContent={'flexStart'} className={'products-page--radio-wrapper'}>
									<label className={'products-page--radio--label'}>
										<input
											onClick={() => changePaymentMethod('account')}
											name={'payment-type'}
											className={'products-page--radio'}
											type={'radio'}
											defaultChecked
										/>
										<div className={'products-page--radio--text'}>
											{paymentMethod === 'account' ? (
												<RadioFilledIcon className={'products-page--radio--icon'} />
											) : (
												<RadioEmptyIcon className={'products-page--radio--icon'} />
											)}
											<span className={'products-page--button--pay'}>{'Pay'}</span>
											<span className={'products-page--button--pal'}>{'Pal'}</span>
										</div>
										{ userInfo?.data?.customer?.is_from_european_union_country ? (
											<Tooltip
												trigger={<InfoIcon className={'products-page--relative-icon'} />}
											>
												<p className={'products-page--tooltip-paraq'}>
													The PayPal payment system is connected to our Taiwanese company. <br /><br />
													No VAT will be charged, and you do not need to provide your VAT number
												</p>
											</Tooltip>
										) : null }
									</label>
									<label className={'products-page--radio--label'}>
										<input
											onClick={() => changePaymentMethod('credit')}
											name={'payment-type'}
											className={'products-page--radio'}
											type={'radio'}
										/>
										<div className={'products-page--radio--text'}>
											{paymentMethod === 'credit' ? (
												<RadioFilledIcon className={'products-page--radio--icon'} />
											) : (
												<RadioEmptyIcon className={'products-page--radio--icon'} />
											)}
											<CreditCard />
											<span className={'products-page--button--credit-span'}>{'Debit or Credit Card'}</span>
										</div>
										{ userInfo?.data?.customer?.is_from_european_union_country ? (
											<Tooltip
												trigger={<InfoIcon className={'products-page--relative-icon'} />}
											>
												<p className={'products-page--tooltip-paraq'}>
													The ‘Debit or Credit card’ payment method is connected to our Cypriot company. <br /><br />
													According to EU regulations, 19% VAT will be added to the cost. <br /><br />
													To avoid the 19% VAT charge, you need to enter and confirm your valid VAT number in the ‘VAT’ field in the ‘Billing Address’ form. <br /><br />
													Once your VAT number is confirmed, the VAT will be 0%, and the system will automatically adjust the purchase amount
												</p>
											</Tooltip>
										) : null }
									</label>
								</Flex>
								<Flex justifyContent={'spaceBetween'} alignItems={'flexEnd'} fullWidth>
									<div className={'products-page--input products-page--input--wide'}>
										<label>{'Have a Promo Code?'}</label>
										<div className={'products-page--relate-input'}>
											<input value={promocodeValue} onChange={(e) => setPromocodeValue(e.target.value)} />
											{discountParams.data.discount ? (
												<button
													onClick={() => handlePromoCodeCancel()}
													className={'products-page--cancel-code'}
												>
													<CloseIcon />
												</button>
											) : null}
										</div>
									</div>
									<button
										disabled={Boolean(promocodeValue === '')}
										onClick={() => handlePromoCodeRedeem()}
										className={classNames(
											'products-page--button products-page--button--activate',
											{
												['products-page--button--activate--disabled']: promocodeValue === ''
											}
										)}
									>
										<Text color={discountParams.data.discount ? 'green' :'black'} size={'SIZE12'} font={'book'}>
											{discountParams.data.discount ? 'Activated' : 'Activate'}
										</Text>
									</button>
								</Flex>
								{promocodeError ? (
									<div>
										<Text color={'error'} size={'SIZE10'}>{promocodeError}</Text>
									</div>
								) : null}
								<div className={'products-page--selected-product'}>
									<Flex className={'products-page--selected-product'} justifyContent={'spaceBetween'} alignItems={'flexStart'} fullWidth>
										<Flex className={'products-page--text'} justifyContent={'flexStart'} flexDirection={'column'}>
											<Text color={'white'} size={'SIZE14'} font={'black'}>
												{ productList?.find((item) => item.id === isPaymentAvailable.productId)?.name }
											</Text>
											<Text color={'grey'} size={'SIZE12'} font={'book'}>
												{ productList?.find((item) => item.id === isPaymentAvailable.productId)?.description }
											</Text>
										</Flex>
										<Flex justifyContent={'flexEnd'} alignItems={'flexEnd'} flexDirection={'column'}>
											<Text color={'white'} size={'SIZE14'} font={'black'}>
												{'$'}
												{ productList?.find((item) => item.id === isPaymentAvailable.productId)?.price }
											</Text>
											{discountParams.data.discount ? (
												<Text color={'pink'} size={'SIZE12'} font={'book'}>
													{`-${discountParams.data.discount}%`}
												</Text>
											) : null}
										</Flex>
									</Flex>
									{ paymentMethod !== 'account' && userInfo?.data?.customer?.is_from_european_union_country ? (
										<Flex className={'products-page--vat-number'} justifyContent={'flexEnd'} alignItems={'center'} fullWidth>
											<Text color={'white'} size={'SIZE12'} font={'black'}>
												{'VAT '}
											</Text>
											<Text className={'products-page--total'} color={'pink'} size={'SIZE14'} font={'black'}>
												{ userInfo?.data?.customer?.is_vat_overprice ? '19%' : '0%'}
											</Text>
										</Flex>
									) : null }
									<Flex justifyContent={'flexEnd'} alignItems={'flexStart'} fullWidth>
										<Text color={'white'} font={'black'}>
											{'TOTAL '}
										</Text>
										<Text className={'products-page--total'} color={'white'} font={'black'}>
											{' $'}
											{ discountParams.data.newPrice ? (
												<>
													{calcPrice(
														userInfo?.data?.customer?.is_vat_overprice,
														discountParams.data.newPrice,
														paymentMethod === 'account'
													)}
												</>
											) : (
												<>
													{calcPrice(
														userInfo?.data?.customer?.is_vat_overprice,
														productList?.find((item) => item.id === isPaymentAvailable.productId)?.price,
														paymentMethod === 'account'
													)}
												</>
											)}
										</Text>
									</Flex>
									{ paymentMethod !== 'account' && userInfo?.data?.customer?.is_from_european_union_country ? (
										<Flex justifyContent={'flexEnd'} alignItems={'flexStart'} fullWidth>
											{userInfo?.data?.customer?.is_vat_overprice
												? (
													<Text color={'pink'} size={'SIZE14'} font={'book'}>
														{'Including VAT'}
													</Text>
												)
												: (
													<Text color={'pink'} size={'SIZE14'} font={'book'}>
														{'Excluding VAT (subject to reverse charge)'}
													</Text>
												)
											}
										</Flex>
									) : null }
								</div>
								<label className={classNames('add-product-page--modal--item')}>
									<Flex justifyContent={'flexStart'} alignItems={'flexStart'}>
										<input
											onClick={() => setIsSendToEmail(!isSendToEmail)}
											type={'checkbox'}
											className={'add-product-page--checkbox'}
										/>
										{isSendToEmail
											? <CheckboxFilledIcon className={'add-product-page--checkbox--icon'} />
											: <CheckboxEmptyIcon className={'add-product-page--checkbox--icon'} />
										}
										<Text size={'SIZE12'} font={'book'} className={'add-product-page--checkbox--text'}>
											{'Upon successful payment, you will receive the activation codes at the email address:'}
											<br />
											{userInfo?.data?.email}
										</Text>
									</Flex>
								</label>
								<Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} fullWidth>
									<Button
										onClick={() => handleProceedToPayment()}
										className={'products-page--proceed-payment'}
										disabled={!isSendToEmail}
										color={'pink'}
										size={'small'}
									>
										{'PROCEED TO PAYMENT'}
									</Button>
									<Button
										onClick={() => handleCancelPayment()}
										className={'products-page--proceed-payment-cancel'}
										color={'white'}
										size={'small'}
									>
										{'CANCEL'}
									</Button>
								</Flex>
							</div>
							<Flex flexDirection={'column'} className={'products-page--payment-side'}>
								<div className={'products-page--text-wrapper'}>
									<Text font={'book'} size={'SIZE14'}>
										{'Billing Address:'}
									</Text>
								</div>
								<div className={'products-page--text-wrapper'}>
									<Text font={'book'} color={'white'} size={'SIZE14'}>
										{'This information will be used'} <br />
										{'for Invoice/Receipt generation:'}
									</Text>
								</div>
								<Flex
									className={'products-page--input-wrapper'}
									justifyContent={'spaceBetween'}
									alignItems={'flexStart'}
									fullWidth
								>
									<div className={'products-page--input products-page--input--wide'}>
										<label>{'Company/Individual Name'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.company_name || ''}
												onChange={(e) => handleChangeBillingAddress('company_name', e?.target?.value)}
											/>
										</div>
									</div>
									<div className={'products-page--input'}>
										<label className={'products-page--relative-label'}>
											{'VAT'}
											{ userInfo?.data?.customer?.is_from_european_union_country ? (
												<Tooltip
													trigger={<InfoIcon className={'products-page--relative-icon'} />}
												>
													<p className={'products-page--tooltip-paraq'}>
														For VAT numbers in EU countries, it is mandatory to include the country
														code at the beginning of the number. For example, a VAT number from
														Cyprus should be entered as CY********. The country codes for each EU country are as follows:
													</p>
													<Flex justifyContent={'flexStart'} alignItems={'center'}>
														<div>
															<p className={'products-page--tooltip-paraq-half'}>• AT - Austria</p>
															<p className={'products-page--tooltip-paraq-half'}>• BE - Belgium</p>
															<p className={'products-page--tooltip-paraq-half'}>• BG - Bulgaria</p>
															<p className={'products-page--tooltip-paraq-half'}>• CY - Cyprus</p>
															<p className={'products-page--tooltip-paraq-half'}>• CZ - Czechia</p>
															<p className={'products-page--tooltip-paraq-half'}>• DE - Germany</p>
															<p className={'products-page--tooltip-paraq-half'}>• DK - Denmark</p>
															<p className={'products-page--tooltip-paraq-half'}>• EE - Estonia</p>
															<p className={'products-page--tooltip-paraq-half'}>• EL - Greece</p>
															<p className={'products-page--tooltip-paraq-half'}>• ES - Spain</p>
															<p className={'products-page--tooltip-paraq-half'}>• FI - Finland</p>
															<p className={'products-page--tooltip-paraq-half'}>• FR - France</p>
															<p className={'products-page--tooltip-paraq-half'}>• HR - Croatia</p>
															<p className={'products-page--tooltip-paraq-half'}>• HU - Hungary</p>
														</div>
														<div>
															<p className={'products-page--tooltip-paraq-half'}>• IE - Ireland</p>
															<p className={'products-page--tooltip-paraq-half'}>• IT - Italy</p>
															<p className={'products-page--tooltip-paraq-half'}>• LT - Lithuania</p>
															<p className={'products-page--tooltip-paraq-half'}>• LU - Luxembourg</p>
															<p className={'products-page--tooltip-paraq-half'}>• LV - Latvia</p>
															<p className={'products-page--tooltip-paraq-half'}>• MT - Malta</p>
															<p className={'products-page--tooltip-paraq-half'}>• NL - The Netherlands</p>
															<p className={'products-page--tooltip-paraq-half'}>• PL - Poland</p>
															<p className={'products-page--tooltip-paraq-half'}>• PT - Portugal</p>
															<p className={'products-page--tooltip-paraq-half'}>• RO - Romania</p>
															<p className={'products-page--tooltip-paraq-half'}>• SE - Sweden</p>
															<p className={'products-page--tooltip-paraq-half'}>• SI - Slovenia</p>
															<p className={'products-page--tooltip-paraq-half'}>• SK - Slovakia</p>
															<p className={'products-page--tooltip-paraq-half'}>• XI - Northern Ireland</p>
														</div>
													</Flex>
												</Tooltip>
											) : null }
										</label>
										<div className={'products-page--relate-input'}>
											<input
												className={
													vatError
														? 'error'
														: ''
												}
												value={billingAddress?.vat_number || ''}
												onChange={(e) => handleChangeBillingAddress('vat_number', e?.target?.value)}
											/>
										</div>

										{vatError ? (
											<div>
												<Text color={'error'} size={'SIZE10'}>{'Incorrect VAT'}</Text>
											</div>
										) : null}
									</div>
								</Flex>
								<Flex
									className={'products-page--input-wrapper'}
									justifyContent={'spaceBetween'}
									alignItems={'flexEnd'}
									fullWidth
								>
									<div className={'products-page--input products-page--input--full'}>
										<label>{'Address'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.address || ''}
												onChange={(e) => handleChangeBillingAddress('address', e?.target?.value)}
											/>
										</div>
									</div>
								</Flex>
								<Flex
									className={'products-page--input-wrapper'}
									justifyContent={'spaceBetween'}
									alignItems={'flexEnd'}
									fullWidth
								>
									<div className={'products-page--input products-page--input--wide-m'}>
										<label>{'Apt, unit, suite (optional)'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.extra || ''}
												onChange={(e) => handleChangeBillingAddress('extra', e?.target?.value)}
											/>
										</div>
									</div>
									<div className={'products-page--input products-page--input--medium'}>
										<label>{'City'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.city || ''}
												onChange={(e) => handleChangeBillingAddress('city', e?.target?.value)}
											/>
										</div>
									</div>
								</Flex>
								<Flex
									className={'products-page--input-wrapper'}
									justifyContent={'spaceBetween'}
									alignItems={'flexEnd'}
									fullWidth
								>
									<div className={'products-page--input products-page--input--wide'}>
										<label>{'Country'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.country_name || ''}
												onChange={(e) => handleChangeBillingAddress('country_name', e?.target?.value)}
											/>
										</div>
									</div>
									<div className={'products-page--input'}>
										<label>{'Postal code'}</label>
										<div className={'products-page--relate-input'}>
											<input
												value={billingAddress?.postal_code || ''}
												onChange={(e) => handleChangeBillingAddress('postal_code', e?.target?.value)}
											/>
										</div>
									</div>
								</Flex>
								<Flex flexDirection={'column'} justifyContent={'flexStart'} alignItems={'flexStart'} fullWidth>
									<Button
										className={'products-page--save-address'}
										color={'grey'}
										size={'small'}
										suffix={billingAddressSaved ? <SuccessIcon /> : undefined}
										onClick={() => handleSaveBillingAddress()}
									>
										<Text color={billingAddressSaved ? 'green' : 'black'}>
											{billingAddressSaved ? 'SAVED' : 'CONFIRM AND SAVE'}
										</Text>
									</Button>
									{billingAddressSaved ? (
										<Text font={'book'} size={'SIZE14'} className={'products-page--billing-address-saved'} color={'green'}>
											{'Billing address saved!'}
										</Text>
									) : null}
								</Flex>
							</Flex>
						</Flex>
					)
				}
			} else if (isHintShow) {
				renderBody = () => {
					return (
						<Flex
							className={'products-page--modal-info'}
							justifyContent={'flexStart'}
							flexDirection={'column'}
						>
							<Text color={'white'} font={'book'}>
								{ TEXT_MOCK[7] }
							</Text>
							<Flex flexDirection={'column'}>
								<ol className={'products-page--list'}>
									<li>
										<Text color={'white'} font={'book'}>
											{'Go to your Wallet.'}
										</Text>
									</li>
									<li>
										<Text color={'white'} font={'book'}>
											{'Click the card you want to confirm.'}
										</Text>
									</li>
									<li>
										<Text color={'white'} font={'book'}>
											{'Click the Confirm Card link. (If you don’t see the link, we’ve automatically confirmed your card and there’s nothing else you need to do.)'}
										</Text>
									</li>
									<li>
										<Text color={'white'} font={'book'}>
											{'Review the information and click OK.'}
										</Text>
									</li>
								</ol>
							</Flex>
							<Text className={'products-page--text-wrapper'} whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[8] }
							</Text>
							<Text className={'products-page--text-wrapper'} whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[9] }
							</Text>
							<Text className={'products-page--text-wrapper'} whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[10] }
							</Text>
							<Text whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[11] }
							</Text>
							<Flex flexDirection={'column'}>
								<ol className={'products-page--list'}>
									<li>
										<Text color={'white'} font={'book'}>
											{'Go to your Wallet.'}
										</Text>
									</li>
									<li>
										<Text color={'white'} font={'book'}>
											{'Click the card you want to confirm.'}
										</Text>
									</li>
									<li>
										<Text color={'white'} font={'book'}>
											{'Enter the 4-digit code and click Confirm.'}
										</Text>
									</li>
								</ol>
							</Flex>
							<Text className={'products-page--text-wrapper'} whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[12] }
							</Text>
							<Text className={'products-page--text-wrapper'} whiteSpace color={'white'} font={'book'}>
								{ TEXT_MOCK[13] }
							</Text>
						</Flex>
					)
				}
				renderControls = () => {
					return (
						<>
							<Button
								onClick={() => setIsHintShow(false)}
								color={'grey'}
								size={'small'}
							>
								{'OK'}
							</Button>
						</>
					)
				}
			}

			return {
				dialogMessage,
				renderBody,
				renderControls
			}
		}
		const { dialogMessage, renderControls, renderBody } = getDialogProps();

		const renderIcon = () => {
			if (isError.isOpen) return <ErrorIcon />
			if (isPaymentDialogOpen || isSuccess.isOpen) return <SuccessIcon />
			if (isPaymentProcessing) return <Preloader />
			return null
		};

		const renderWidth = () => {
			if (isPaymentAvailable.isOpen) return '50em';
			if (isDialogOpen) return '28em';
			if (isHintShow) return '80%';
			return undefined;
		};

		return <Dialog
			icon={renderIcon()}
			isOpen={isOpen}
			width={renderWidth()}
			renderBody={renderBody}
			message={dialogMessage}
			renderControls={renderControls}
		/>
	}

	return (
		<div className={'products-page'}>
			<BackButton />
			<Text
				transform={'uppercase'}
				font={'black'}
				size={'SIZE22'}
				color={'pink'}
			>
				{'Packages'}
			</Text>
			{renderProducts()}
			{renderDialog()}
			{isLoading ? (
				<BackgroundBlocker>
					<Preloader />
				</BackgroundBlocker>
			) : null}
			<div className={'global--log'}>
				<div>
					{'authToken: '}
					{authToken}
				</div>
			</div>
		</div>
	)
}
