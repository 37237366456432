import {FunctionComponent, useEffect, useState} from "react";
import { Text } from "../Text/Text";
import { Flex } from "../Flex/Flex";
import { IDialog } from "./Dialog.interfaces";
import { BackgroundBlocker } from "../BackgroundBlocker/BackgroundBlocker";
import classNames from "classnames";
import { useRef } from 'react';

export const Dialog : FunctionComponent<IDialog> = ({
	isOpen,
	icon,
	width,
	title,
	message,
	renderControls,
	renderBody,
	messageAlign= 'center',
	messageMinHeight
}) => {
	const modalRef = useRef<HTMLDivElement | null>(null);
	const [fitIn, setFitIn] = useState(false);

	function handleResize() {
		if (modalRef?.current?.clientHeight) {
			const fitResult = modalRef?.current?.clientHeight > window.innerHeight;
			if (fitIn !== fitResult) {
				setFitIn(fitResult);
			}
		}
	}

	useEffect(() => {
		setTimeout(() => handleResize());
	}, [isOpen, handleResize]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [handleResize]);

	if (!isOpen) return null;

	const messageClasses = classNames('dialog--message', {
		['dialog--message--align-center']: messageAlign === 'center',
		['dialog--message--align-left']: messageAlign === 'left'
	});

	return (
		<BackgroundBlocker overflow={fitIn} >
			<div ref={modalRef} style={{ width: `${width}` }} className={'dialog--inside'}>
				{renderBody ? renderBody() : (
					<>
						{icon ? (
							<div className={'dialog--icon'}>
								{icon}
							</div>
						): null}
						{title ? (
							<div className={'dialog--title'}>
								<Text
									font={'medium'}
									size={'SIZE22'}
									color={'pink'}
								>
									{title}
								</Text>
							</div>
						) : null}
						{message ? (
							<div style={{ height: `${messageMinHeight}` }} className={messageClasses}>
								<Text
									font={'book'}
									size={'SIZE14'}
									color={'white'}
								>
									{message}
								</Text>
							</div>
						) : null}
					</>
				)}
				{renderControls ? (
					<Flex className={'dialog--wrapper'} justifyContent={'center'}>
						{renderControls()}
					</Flex>
				) : null}
			</div>
		</BackgroundBlocker>
	)
}
