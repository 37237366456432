import {RouteProps} from "react-router";

export enum pageNames {
	TEST = 'TEST',
	HOME = 'HOME',
	DATABASE = 'DATABASE',
	BOOKMARKS = 'BOOKMARKS',
	ARCHIVE = 'ARCHIVE',
	KAVACAION = 'KAVACAION',
	SOFTWAREGUIDE = 'SOFTWAREGUIDE',
	JOIN = 'JOIN',
	PRODUCTS = 'PRODUCTS',
	ADDPRODUCTS = 'ADDPRODUCTS'
}

type Routes = {
	title: string;
	isMenu?: boolean;
	disabled?: boolean;
}

export type IRoutesType = Routes & RouteProps;
