import {FunctionComponent, PropsWithChildren} from "react";
import {SideMenu} from "../SideMenu/SideMenu";
import {sideMenuRoutes} from "../../routes";
import {Link} from "react-router-dom";

export const MainLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
	return (
		<div className={'main-layout'}>
			<div className={'main-layout-header'}>
				<Link className={'main-layout-logo'} to={'/'}>KAVACA SMART CUT</Link>
			</div>
			<div className={'main-layout-menu'}>
				<SideMenu items={sideMenuRoutes} />
			</div>
			<div className={'main-layout-body'}>
				{children}
			</div>
		</div>
	)
};
