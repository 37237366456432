import React, {FunctionComponent} from "react";
import {Text} from "../../components/Text/Text";
import {TEXT_MOCK} from "./KavacaIONPage.constants";
import {Flex} from "../../components/Flex/Flex";
import {BackButton} from "../../components/BackButton/BackButton";

export const KavacaIONPage: FunctionComponent = () => {

	return (
		<div className={'kavaca-ion-page'}>
			<BackButton />
			<Text font={'black'} size={'SIZE22'} color={'pink'}>{TEXT_MOCK[0]}</Text>
			<div>
				<Text font={'black'} size={'SIZE20'}>{TEXT_MOCK[1]}</Text>
			</div>
			<div className={'kavaca-ion-page--img'}>
				<img alt={'kavaca-main-ion-image'} src={'/assets/image/KVC_ION_1-100.jpg'} />
			</div>
			<Text font={'light'} size={'SIZE14'}>{TEXT_MOCK[2]}</Text>
			<Flex justifyContent={'spaceBetween'}>
				<div className={'kavaca-ion-page--half-item'}>
					<div className={'kavaca-ion-page--img'}>
						<img alt={'kavaca-main-ion-image'} src={'/assets/image/KVC_ION_2-100.jpg'} />
					</div>
					<Text font={'light'} size={'SIZE14'}>{TEXT_MOCK[3]}</Text>
				</div>
				<div className={'kavaca-ion-page--half-item'}>
					<div className={'kavaca-ion-page--img'}>
						<img alt={'kavaca-main-ion-image'} src={'/assets/image/KVC_ION_3-100.jpg'} />
					</div>
					<Text font={'light'} size={'SIZE14'}>{TEXT_MOCK[4]}</Text>
				</div>
			</Flex>
			<div className={'kavaca-ion-page--sub-title'}>
				<Text font={'black'} size={'SIZE20'}>{TEXT_MOCK[5]}</Text>
			</div>
			<Flex justifyContent={'spaceBetween'}>
				<div className={'kavaca-ion-page--half-item'}>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[6]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[7]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[8]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[9]}</Text>
					</div>
					<div className={'kavaca-ion-page--img'}>
						<img alt={'kavaca-main-ion-image'} src={'/assets/image/KVC_ION_4-100.jpg'} />
					</div>
					<Text font={'light'} size={'SIZE14'}>{TEXT_MOCK[14]}</Text>
				</div>
				<div className={'kavaca-ion-page--half-item'}>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[10]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[11]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[12]}</Text>
					</div>
					<div className={'kavaca-ion-page--list'}>
						<Text font={'light'} size={'DEFAULT'}>{TEXT_MOCK[13]}</Text>
					</div>
					<div className={'kavaca-ion-page--img'}>
						<img alt={'kavaca-main-ion-image'} src={'/assets/image/KVC_ION_5-100.jpg'} />
					</div>
					<Text font={'light'} size={'SIZE14'}>{TEXT_MOCK[15]}</Text>
				</div>
			</Flex>
			<Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} className={'join-us-page--qr-code'}>
				<Text size={'SIZE24'} transform={'uppercase'} color={'white'}>GET KAVACA ION</Text>
				<img className={'join-us-page--qr-code--img'} alt={''} src={'/assets/image/KAVACA_ION_QR.png'} />
				<Flex className={'join-us-page--qr-code--bottom-text'} justifyContent={'center'}>
					<Text font={'black'}>{TEXT_MOCK[17]}</Text>
				</Flex>
				<Flex justifyContent={'center'}>
					<Text font={'black'}>{TEXT_MOCK[18]}</Text>
				</Flex>
			</Flex>
		</div>
	)
}
